export class EG {
  type: 'IMPORT' | 'EXPORT' = 'IMPORT';
  containerNumber: null;
  cassisNumber: null;
  date: null;
  chassis: null;
  scac: null;
  remark: null;
  to: 'IN' | 'OUT' = 'IN';
  masterBillOfLading: null;
  vessel: null;
  carrier: null;
  status: 'LOAD' | 'EMPTY' = 'LOAD';
  bookingNumber: null;
  sealNumber: null;
}
