

















































































































































import { Component, Vue } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';

import { EG } from './models/EG.model';

@Component({
  components: {
    Fragment
  }
})
export default class HDAction extends Vue {
  item = new EG();
  form: any = this.item;

  type = [
    { value: 'IMPORT', text: 'IMPORT' },
    { value: 'EXPORT', text: 'EXPORT' }
  ];

  status = [
    { value: 'LOAD', text: 'LOAD' },
    { value: 'EMPTY', text: 'EMPTY' }
  ];

  to = [
    { value: 'IN', text: 'IN' },
    { value: 'OUT', text: 'OUT' }
  ];

  constructor() {
    super();
  }

  created() {}

  save() {}
}
