import {
  CellContentType,
  TableHeaders
} from '@/shared/components/table/models';

export const EGHeaders: TableHeaders[] = [
  {
    key: 'user',
    label: `User`,
    sortable: true
  },
  {
    key: 'date',
    label: `Date`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'type',
    label: `IE/TYPE`,
    sortable: true
  },
  {
    key: 'inOut',
    label: `IN/OUT`,
    sortable: true
  },
  {
    key: 'loadEmpty',
    label: `LOAD/EMPTY`,
    sortable: true,
    disabled: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'transaction',
    label: `Transaction`,
    sortable: true
  },
  {
    key: 'time',
    label: `Time`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Time
    }
  },
  {
    key: 'mbl',
    label: `MBL #`,
    sortable: true
  },
  {
    key: 'bk',
    label: `BK #`,
    sortable: true
  }
];
